import AuthServices from "src/lt/auth-module/AuthServices";
import { GeneralService } from "src/lt/general-module/GeneralService";
import { secureStorage } from "src/lt/utils/secureStorage";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    config: {},
    permissions: []
  },
  mutations: {
    setConfig(state, payload) {
      state.config = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setPermissions(state, payload) {
      state.permissions = payload;
    }
  },
  actions: {
    async setup({ commit }) {
      try {
        if (AuthServices.checkIfHasToken()) {
          let user = await GeneralService.getUser();
          commit("setPermissions", user.permissions);
          secureStorage.setItem(
            "permissions",
            JSON.stringify(user.permissions)
          );
          delete user.permissions;
          commit("setUser", user);
          commit("setConfig", user.config);
          console.log("logued");
        } else {
          throw "no logued";
        }
      } catch (e) {
        console.log("no autenticado");
        AuthServices.removeToken();
      }
    }
  },
  modules: {}
});
