/**
 * Author: Wilson Pilco Nuñez
 * Email: wilsonaux1@gmail.com
 * Created at: 2023-04-09 21:18
 */

const {secureStorage} = require("./secureStorage");

var permissions = [];

function getPermissionsFromStorage() {
  permissions = JSON.parse(secureStorage.getItem("permissions"));
}

function hasPermissionTo(permission) {
  if(permissions.length == 0) {
    getPermissionsFromStorage();
  }
  return permissions.includes(permission);
}

export { hasPermissionTo };
