<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    //
  }
};
</script>

<style>
@import "scss/style.css";
</style>
