import { adminRoutes } from "src/lt/admin-module";
import { authGuard } from "src/lt/auth-module";
import { logisticRoutes } from "src/lt/logistic-module";
import { storeRoutes } from "src/lt/store-module";
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("../components/LoginPage.vue"),
    name: "login"
  },
  {
    path: "",
    component: () => import("../components/Container.vue"),
    meta: { requiresAuth: true },
    children: [
      adminRoutes,
      storeRoutes,
      logisticRoutes,
      {
        path: "/dashboard",
        component: () => import("../micro/cashregister/AutoContainer.vue"),
        meta: { permission: "mc_access" },
      },
      {
        path: "/service-plan-daily",
        component: () => import("../micro/service/Container.vue"),
        meta: { permission: "srv_plan_daily" },
      },
      {
        path: "/service-report",
        component: () => import("../micro/service/ReportPage.vue"),
      }
    ]
  },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home
  // },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(authGuard);

export default router;
